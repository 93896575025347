import originalFirebase from "firebase/app";
import "firebase/auth";
import { Box } from "grommet";
import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useSelector } from "react-redux";
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestore
} from "react-redux-firebase";
import { useHistory, useRouteMatch } from "react-router";
import AppBar from "./components/AppBar";
import Loader from "./components/Loader";
import { RootStore } from "./types";

const Container: React.FC = props => (
  <React.Fragment>
    <AppBar inUserSpace />
    <Box as="main" pad="xlarge" fill="horizontal">
      {props.children}
    </Box>
  </React.Fragment>
);

type Props = {
  desktopSignIn?: boolean;
};

const LoginWrapper: React.FC<Props> = props => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const auth = useSelector((state: RootStore) => state.firebase.auth);
  const match = useRouteMatch<{ anonUid: string }>();
  const history = useHistory();

  const onSignInSuccess = (authResult: any, test: any) => {
    // Unless this is for desktop sign-in, simply redirect.
    if (!props.desktopSignIn) return true;
    // If desktop-signing-in, send the credentials to the anonymous user.
    const credential = JSON.stringify(authResult.credential.toJSON());
    firestore
      .collection("desktopLogins")
      .doc(match.params["anonUid"])
      .set({
        credential: credential,
        timestamp: originalFirebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        console.log("Sent credentials.");
        history.push("/desktopSignInSuccess");
      })
      .catch(e => {
        console.error("Failed to send credential.");
        console.error(e);
      });
    // Don't redirect, because otherwise it cancels my firestore.set()
    return false;
  };

  if (!isLoaded(auth))
    return (
      <Container>
        <Loader />
      </Container>
    );

  if (isEmpty(auth))
    return (
      <Container>
        <StyledFirebaseAuth
          uiConfig={{
            signInFlow: "redirect",
            signInSuccessUrl: props.desktopSignIn
              ? "/desktopSignInSuccess"
              : "/subscribe",
            signInOptions: [
              originalFirebase.auth.GoogleAuthProvider.PROVIDER_ID
            ],
            callbacks: {
              signInSuccessWithAuthResult: onSignInSuccess
            }
          }}
          firebaseAuth={firebase.auth()}
        />
      </Container>
    );

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default LoginWrapper;
