import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database"; // Probably needed to keep rrf working
import "firebase/firestore"; // <- needed if using firestore
// import 'firebase/functions' // <- needed if using httpsCallable
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore"; // <- needed if using firestore
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import firebaseConfig from "./firebaseConfig";
import { ThemeType, Grommet } from "grommet";
import { Colors } from "./consts";

// react-redux-firebase config
const rrfConfig = {
  //   userProfile: "users",
  //   useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
});

// Create store with reducers and initial state
const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

const theme: ThemeType = {
  global: {
    colors: {
      brand: Colors.brand,
      text: Colors.text,
      focus: Colors.focus,
    },
    font: {
      size: "18px",
      height: "1.5",
    },
  },
  paragraph: {
    small: {
      height: "1.5",
    },
    medium: {
      height: "1.5",
    },
    large: {
      height: "1.5",
    },
    xlarge: {
      height: "1.5",
    },
    xxlarge: {
      height: "1.5",
    },
  },
  heading: {
    level: {
      1: {
        small: {
          height: "1.5",
        },
        medium: {
          height: "1.5",
        },
        large: {
          height: "1.5",
        },
        xlarge: {
          height: "1.5",
        },
      },
      2: {
        small: {
          height: "1.5",
        },
        medium: {
          height: "1.5",
        },
        large: {
          height: "1.5",
        },
        xlarge: {
          height: "1.5",
        },
      },
      3: {
        small: {
          height: "1.5",
        },
        medium: {
          height: "1.5",
        },
        large: {
          height: "1.5",
        },
        xlarge: {
          height: "1.5",
        },
      },
      4: {
        small: {
          height: "1.5",
        },
        medium: {
          height: "1.5",
        },
        large: {
          height: "1.5",
        },
        xlarge: {
          height: "1.5",
        },
      },
      5: {
        small: {
          height: "1.5",
        },
        medium: {
          height: "1.5",
        },
        large: {
          height: "1.5",
        },
        xlarge: {
          height: "1.5",
        },
      },
      6: {
        small: {
          height: "1.5",
        },
        medium: {
          height: "1.5",
        },
        large: {
          height: "1.5",
        },
        xlarge: {
          height: "1.5",
        },
      },
    },
  },
};

// Setup react-redux so that connect HOC can be used
function MyRoot() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Grommet theme={theme} full>
          <App />
        </Grommet>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}

const rootElement = document.getElementById("root");
if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<MyRoot />, rootElement);
} else {
  ReactDOM.render(<MyRoot />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
