import { Anchor, Box, Drop } from "grommet";
import React, { useRef, useState } from "react";

type Props = {
  href: string;
  tooltip: string;
};

const DownloadButton: React.FC<Props> = props => {
  const [over, setOver] = useState();
  const ref = useRef<HTMLAnchorElement>(null);

  return (
    <Anchor
      ref={ref}
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
      onFocus={() => {}}
      onBlur={() => {}}
      href={props.href}
    >
      {props.children}

      {ref.current && over && (
        <Drop align={{ top: "bottom" }} target={ref.current} plain>
          <Box
            margin="xsmall"
            pad="small"
            background="white"
            round="medium"
            color="text"
          >
            {props.tooltip}
          </Box>
        </Drop>
      )}
    </Anchor>
  );
};

export default DownloadButton;
