import { Anchor, Box, Button, Menu, ResponsiveContext } from "grommet";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { goTo } from "../actions/anchorLinks";
import { Colors } from "../consts";
import logo from "../img/logo.svg";
import { useTypedSelector } from "../types";
import firebase from "firebase/app";

const Title = styled.span`
  color: ${Colors.text};
  opacity: 1;
  font-size: 30px;
  font-weight: bold;
  margin-left: 20px;
  text-decoration: none;
`;

const HomeLink = styled.a`
  color: ${Colors.text};
  &:link,
  &:active,
  &:hover,
  &:visited {
    text-decoration: none;
  }
`;

const BarLink = styled(Anchor)`
  color: ${Colors.text};
  font-weight: normal;

  // it's necessary to use all 3 to properly override the default value
  // in Firefox 66.0.5 Windows.
  &:link,
  &:active,
  &:visited {
    text-decoration: none;
    color: ${Colors.text};
  }
  &:hover {
    text-decoration: underline;
    color: black;
  }
`;

type Props = {
  inUserSpace?: boolean;
};

const AppBar: React.FC<Props> = (props = { inUserSpace: true }) => {
  const history = useHistory();
  const auth = useTypedSelector((props) => props.firebase.auth);
  const goToAccount = () => {
    history.push("/subscribe");
  };
  const pushSection = (id: string) => {
    // Need a timeout because the Menu first needs to
    // get the focus back.
    if (history.location.pathname === "/") setTimeout(() => goTo(id), 16);
    else history.push("/#" + id);
  };
  const signOut = () => {
    console.log("Signing out...");
    firebase.auth().signOut();
    history.push("");
  };
  const isSmall = React.useContext(ResponsiveContext) === "small";

  const items = !props.inUserSpace
    ? [
        { label: "Download", onClick: () => pushSection("download") },
        { label: "Pricing", onClick: () => pushSection("pricing") },
        { label: "Support", onClick: () => pushSection("faq") },
        { label: "My account", onClick: goToAccount },
      ]
    : auth.isEmpty
    ? []
    : [
        { label: auth.email, disabled: true },
        { label: "Sign out", onClick: signOut },
      ];

  return (
    <Box
      as="nav"
      direction="row"
      align="center"
      justify="between"
      background="white"
      pad={{ left: "medium", right: "small", vertical: "small" }}
      elevation="medium"
      {...props}
    >
      <HomeLink href="/">
        <Box fill={false} direction="row" align="center">
          <img src={logo} alt="PlaylistSync logo" />

          {(!isSmall || items.length === 0) && <Title>PlaylistSync</Title>}
        </Box>
      </HomeLink>
      {isSmall ? (
        items.length > 0 && (
          <Menu size="large" label="PlaylistSync" items={items} />
        )
      ) : props.inUserSpace ? (
        <Box fill={false} direction="row" align="center" gap="medium">
          {!auth.isEmpty && auth.email}
          {!auth.isEmpty && (
            <Button label="Sign out" color={Colors.text} onClick={signOut} />
          )}
        </Box>
      ) : (
        <Box fill={false} direction="row" align="center" gap="medium">
          <BarLink href="/#download">Download</BarLink>
          <BarLink href="/#pricing">Pricing</BarLink>
          <BarLink href="/#faq">Support</BarLink>
          <Button
            label="My account"
            color={Colors.text}
            onClick={goToAccount}
          />
        </Box>
      )}
    </Box>
  );
};

export default AppBar;
