import React from "react";
import { Footer, Anchor } from "grommet";

const PSFooter: React.FC = () => {
  return (
    <Footer pad="large" direction="column">
      <Anchor href="http://www.desaxed.com" label="by Desaxed Studios" />
      <Anchor
        href="http://www.desaxed.com/playlistsync-privacy.html"
        label="Privacy Policy"
      />
      <Anchor
        href="http://www.desaxed.com/playlistsync-tos.html"
        label="Terms of Service"
      />
    </Footer>
  );
};

export default PSFooter;
