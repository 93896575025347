import firebase from "firebase/app";
import "firebase/functions";
import { Anchor, Box, Button, Paragraph } from "grommet";
import React, { useState } from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { Route, Switch, useRouteMatch } from "react-router";
import Stripe from "stripe";
import { CustomerSummary, useTypedSelector } from "../types";
import ErrorBox from "./ErrorBox";
import Loader from "./Loader";
import PrimaryButton from "./PrimaryButton";
import SubscriptionStatus from "./SubscriptionStatus";

const SubscribeScreen: React.FC = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const match = useRouteMatch();
  const auth = useTypedSelector(state => state.firebase.auth);
  const stripeCustomer = useTypedSelector(
    state => state.firestore.data.stripeCustomer
  ) as Stripe.Customer | undefined;
  const customerSummary = useTypedSelector(
    state => state.firestore.data.customerSummary
  ) as CustomerSummary | undefined;

  useFirestoreConnect({
    collection: "stripeCustomers",
    doc: auth.uid,
    storeAs: "stripeCustomer"
  });

  useFirestoreConnect({
    collection: "customerSummary",
    doc: auth.uid,
    storeAs: "customerSummary"
  });

  const subscribe = () => {
    const dev = process.env.NODE_ENV === "development";
    setLoading(true);
    firebase
      .functions()
      .httpsCallable("createcheckoutsession")({
        plan_id: dev ? "plan_Gls1ppSAaE1pWZ" : "plan_playlistsync_premium",
        live: !dev
      })
      .then(result => {
        setLoading(false);
        console.log(result);
        if (!result) {
          setError(
            "Failed to start checkout: No information sent back from the server."
          );
          return;
        }
        if (
          result &&
          result.data &&
          result.data.status &&
          result.data.status >= 400
        ) {
          setError(`Error ${result.data.status}: ${result.data.message}`);
          return;
        }

        const sessionId = result.data.sessionId;
        if (!sessionId) {
          setError("Couldn't retrieve a session ID from our server.");
          return;
        }
        const stripe = (window as any).Stripe(
          dev
            ? "pk_test_pgBZOqCTAgOQgo5GAvcj092Y00Qlb5Ap2m"
            : "pk_live_6jiCT4vrpR6FI9m7roLg2Iof00swn67GMH"
        );
        stripe
          .redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: sessionId
          })
          .then((result: any) => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            if (result && result.error && result.error.message)
              setError(result.error.message);
          });
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  const refreshData = () => {
    const dev = process.env.NODE_ENV === "development";
    if (!stripeCustomer) return;
    setLoading(true);
    firebase
      .functions()
      .httpsCallable("refreshCustomerData")({
        customer_id: stripeCustomer.id,
        live: !dev
      })
      .then(result => {
        setLoading(false);
        console.log(result);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  const hasActiveSubscription = customerSummary && customerSummary.active;

  return (
    <Box pad="xlarge" align="center">
      <Box width={{ max: "medium" }}>
        <ErrorBox error={error} />
        <Switch>
          <Route path={`${match.path}/success`}>
            <div>
              <Paragraph>Subscribed with success!</Paragraph>
              <Anchor href={match.path}>Ok</Anchor>
            </div>
          </Route>
          <Route path={`${match.path}/cancel`}>
            <div>
              <Paragraph>Checkout canceled</Paragraph>
              <Anchor href={match.path}>Ok</Anchor>
            </div>
          </Route>
          <Route path={`${match.path}/updated`}>
            <div>
              <Paragraph>Payment method updated with success!</Paragraph>
              <Anchor href={match.path}>Ok</Anchor>
            </div>
          </Route>
          <Route>
            {loading ||
            typeof stripeCustomer === "undefined" ||
            typeof customerSummary === "undefined" ? (
              <Loader />
            ) : (
              <React.Fragment>
                <Paragraph>
                  You're currently signed in as {auth.displayName} ({auth.email}
                  ).
                </Paragraph>
                <Paragraph>
                  {hasActiveSubscription
                    ? "You have an active subscription."
                    : "You don't have an active subscription."}
                </Paragraph>
                {stripeCustomer &&
                  stripeCustomer.subscriptions &&
                  stripeCustomer.subscriptions.data &&
                  stripeCustomer.subscriptions.data.map(subscription => (
                    <SubscriptionStatus
                      key={subscription.id}
                      subscription={subscription}
                    />
                  ))}
                {stripeCustomer && (
                  <Button
                    label="Refresh data"
                    margin={{ vertical: "small" }}
                    onClick={refreshData}
                  />
                )}
                {!hasActiveSubscription && (
                  <PrimaryButton
                    label="Subscribe"
                    primary
                    onClick={subscribe}
                    margin={{ vertical: "small" }}
                  />
                )}
                <Button
                  label="Sign out"
                  margin={{ vertical: "small" }}
                  onClick={() => firebase.auth().signOut()}
                />
              </React.Fragment>
            )}
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default SubscribeScreen;
