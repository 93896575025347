import React from "react";
import { ReactComponent as AndroidLogo } from "./img/android.svg";
import { ReactComponent as AppleLogo } from "./img/apple.svg";
import { ReactComponent as FedoraLogo } from "./img/fedora.svg";
import { ReactComponent as UbuntuLogo } from "./img/ubuntu.svg";
import { ReactComponent as WindowsLogo } from "./img/windows.svg";
import { Box, Heading, Anchor } from "grommet";
import { Colors } from "./consts";
import DownloadButton from "./components/DownloadButton";
import styled from "styled-components";

const Container = styled(Box).attrs(() => ({
  align: "center",
  gap: "small",
}))`
  text-align: center;
`;

const Downloads: React.FC = () => {
  return (
    <Container>
      <Box
        direction="row"
        align="center"
        alignContent="center"
        justify="center"
        wrap
        gap="medium"
        round
        pad="large"
        margin="none"
        elevation="xlarge"
        background="brand"
        width={{ max: "840px" }}
      >
        <Heading
          color={Colors.overBrand}
          level="2"
          margin={{ right: "medium", vertical: "none" }}
        >
          Download
        </Heading>
        {/* <a href="https://play.google.com/store/apps/details?id=com.desaxed.playlistsync&utm_source=playlistsync-website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      className="google-play-badge"
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    />
                  </a> */}
        <DownloadButton
          tooltip="Android"
          href="https://play.google.com/store/apps/details?id=com.desaxed.playlistsync&utm_source=playlistsync-website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <AndroidLogo />
        </DownloadButton>
        <DownloadButton
          tooltip="macOS"
          href="https://playlistsync-hazel.now.sh/download/dmg"
        >
          <AppleLogo />
        </DownloadButton>
        <DownloadButton
          tooltip="Windows"
          href="https://playlistsync-hazel.now.sh/download/exe"
        >
          <WindowsLogo />
        </DownloadButton>
        <DownloadButton
          tooltip="Ubuntu/Debian"
          href="https://playlistsync-hazel.now.sh/download/deb"
        >
          <UbuntuLogo />
        </DownloadButton>
        <DownloadButton
          tooltip="Fedora"
          href="https://playlistsync-hazel.now.sh/download/rpm"
        >
          <FedoraLogo />
        </DownloadButton>
      </Box>
      <Anchor
        color="#00000077"
        alignSelf="center"
        size="medium"
        href="https://github.com/nathanvogel/playlistsync-releases/releases"
      >
        Older versions
      </Anchor>
    </Container>
  );
};

export default Downloads;
