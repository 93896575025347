import "firebase/auth";
import React from "react";
import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { RootStore } from "./types";
import { Paragraph, Button } from "grommet";
import styled from "styled-components";

const LoginStatus = styled.div`
  text-align: left;
  max-width: 660px;
`;

const ShowLoginStatus: React.FC = _ => {
  const firebase = useFirebase();
  const auth = useSelector((state: RootStore) => state.firebase.auth);
  return (
    <LoginStatus>
      <Paragraph>
        You're currently signed in as {auth.displayName} ({auth.email}). To
        authorize the desktop app, you need to sign out of your PlaylistSync
        account and sign in again.
      </Paragraph>
      <Button primary onClick={() => firebase.auth().signOut()}>
        Sign out
      </Button>
      {/* <pre>{JSON.stringify(firebase.auth().currentUser, null, 2)}</pre> */}
    </LoginStatus>
  );
};

export default ShowLoginStatus;
