import { Heading } from "grommet";
import styled from "styled-components";

const MainSectionTitle = styled(Heading).attrs(props => ({
  level: props.level || "2"
}))`
  width: 100%;
  max-width: 100%;
  text-align: center;
`;

export default MainSectionTitle;
