import styled from "styled-components";

type SpacingProps = {
  height?: number;
};

const Spacing = styled.div<SpacingProps>`
  height: ${props => props.height || 1}em;
  width: 100%;
`;
export default Spacing;
