import React from "react";
import { Box, Paragraph } from "grommet";
import { Colors } from "../consts";

type Props = {
  error?: string;
};

const ErrorBox: React.FC<Props> = ({ error }) => {
  if (!error) return null;

  return (
    <Box
      fill="horizontal"
      pad="large"
      margin={{ vertical: "large" }}
      round={true}
      background={Colors.error}
    >
      <Paragraph>{error}</Paragraph>
    </Box>
  );
};

export default ErrorBox;
