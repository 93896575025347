import { Accordion, AccordionPanel, Heading, Paragraph } from "grommet";
import React from "react";
import styled from "styled-components";
import MainSectionTitle from "./components/MainSectionTitle";
import List from "./components/List";

const FAQContainer = styled(Accordion).attrs((_) => ({ multiple: true }))`
  ul {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
`;

const FAQSectionHeading = styled(Heading).attrs((_) => ({ level: "3" }))`
  margin-top: 2.4em;
  margin-bottom: 0.55em;
`;

const FAQQuestion = styled(AccordionPanel).attrs((_) => ({}))`
  margin-top: 0.5em;
  margin-bottom: 0.55em;
`;

const FAQParagraph = styled(Paragraph).attrs((_) => ({}))``;

const FAQ: React.FC = () => {
  return (
    <FAQContainer>
      <MainSectionTitle>Frequently Asked Questions</MainSectionTitle>
      <FAQQuestion label="What does PlaylistSync do?">
        <FAQParagraph>
          It saves your playlists (the names and order of music files) to your
          PlaylistSync account. When it restores your playlists, it can process
          those file names and paths, so that they still make sense on a
          different device.
        </FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="Does it save my music?">
        <FAQParagraph>No. Only the filepath and some tags.</FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="I've accidentally deleted a backup!">
        <FAQParagraph>
          The last version of each playlist, even marked as removed, stays on
          our server and can be restored anytime in the "Version History" screen
          for free.
        </FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="What happens to empty playlists?">
        <FAQParagraph>
          They're ignored, for example in case you temporarily removed your SD
          card. So they're neither backed up, nor marked as removed.
        </FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="How long are backups kept?">
        <FAQParagraph>
          All playlists versions are kept for at least 30 days. Afterwards they
          might get progressively deleted (except for the last version).
        </FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="Some backup versions seem to be missing, why?">
        <FAQParagraph>
          New playlist versions are only created when a playlist has changes and
          has reached our server. This means that if you create multiple backups
          offline of the same playlist, only the last one will get archived as a
          version.
        </FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="How can I restore my Google Drive files?">
        <FAQParagraph>
          The easiest is to install PlaylistSync Desktop on a computer and
          import your .playlistsync files. They'll then be available to your
          Android/Windows/macOS/Linux devices through your PlaylistSync account.
        </FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="How can I contact you?">
        <FAQParagraph>
          If you can't find an answer on this page, please contact us at
          team@desaxed.com
        </FAQParagraph>
      </FAQQuestion>

      <FAQSectionHeading>Android</FAQSectionHeading>
      <FAQQuestion label="Android: When should I bother with Path Substitution?">
        <FAQParagraph>
          There're 2 cases in which Path Substitution is useful:
        </FAQParagraph>
        <FAQParagraph>
          <b>1.</b> If you have multiple Android devices and they store music
          differently, your files will always show up as out of sync after a
          backup/restore.
        </FAQParagraph>
        <b>2.</b> If you both:
        <List>
          <li>have multiple audio files with the same name.</li>
          <li>
            have devices that store music under different folders (
            <i>/mnt/sdcard/Music/somefile.mp3</i> on one device and{" "}
            <i>/storage/emulated/0/Music/somefile.mp3</i> on the other)
          </li>
        </List>
      </FAQQuestion>

      <FAQQuestion label="Android: I can't see my playlists!">
        <FAQParagraph>
          Please make sure that PlaylistSync has permission to read your media
          storage. Also, some music players use their own playlists systems
          instead of the default one from Android, so PlaylistSync won't support
          them.
        </FAQParagraph>
        <FAQParagraph>
          Incompatible: <i>Poweramp, RealPlayer...</i>
        </FAQParagraph>
        <FAQParagraph>
          Partially compatible: <i>Samsung Music and Google Play Music.</i>
        </FAQParagraph>
        <FAQParagraph>
          Compatible: <i>PlayerPro, Rocket Player, jetAudio...</i>
        </FAQParagraph>
        <FAQParagraph>
          For more help, please checkout the "Not seeing your latest playlists?"
          button in the Backup tab.
        </FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="Android: How should my music files be named?">
        <FAQParagraph>
          For Android, it's best if every single music file has a different
          name. (For example: you shouldn't have multiple "audio01.mp3" in
          different folders)
        </FAQParagraph>
        <FAQParagraph>
          If you do have files with identical names, you might have to use the
          Path Substitution option to make sure PlaylistSync finds the correct
          audio files on different devices.
        </FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="Android: When restoring, I get 'Failed to create playlist'. What can I do?">
        <FAQParagraph>
          There seems to be a bug in Android that sometimes prevents the
          creation of playlists with the same name as one that previously
          existed. The only solution to this (except contacting me and asking me
          to manually change the name of your backed up playlists) is to clear
          the Media Storage app. However, this will DELETE ALL YOUR PLAYLISTS.
          If you have backups on PlaylistSync, you'll be fine as you can restore
          them afterwards.
        </FAQParagraph>
        <List>
          <li>Step 1: Go to "Settings" > "Apps" (> "Apps").</li>
          <li>
            Step 2: Click the three dots in the top-right corner and choose
            "Show system apps".
          </li>
          <li>Step 3: Search for "Media Storage".</li>
          <li>Step 4: Storage > Clear storage.</li>
        </List>
        <FAQParagraph>
          Now your music and playlists won't show up anywhere. Restart your
          phone so that it searches for your music. PlaylistSync should now be
          able to re-create thoses playlists.
        </FAQParagraph>
      </FAQQuestion>

      <FAQQuestion label="Android 10: Failed to restore">
        <FAQParagraph>
          A bug introduced in Android 10 makes it impossible for non-system apps
          like PlaylistSync to edit playlists if your music files are located on
          an external storage. Google will provide a fix in a future Android
          update. For now, there are 3 possible workarounds:
          <List>
            <li>
              1. Move your files to internal storage (restart your phone and
              allow a few minutes for Android to discover the new location of
              your music library).
            </li>
            <li>
              2. Depending on your phone, it might also be possible to mount the
              SD card as part of internal storage in the settings after ejecting
              it.
            </li>
            <li>
              3. Export .m3u playlists from our desktop app (Download it on{" "}
              <u>https://playlistsync.app/</u>) and put these files on your
              phone storage. Android should auto-import them. Please make sure
              that the file paths match exactly the paths on your phone with the
              Path Substitution option. This method requires a PlaylistSync
              Premium account, but you can try it for free with 1 playlist.
            </li>
          </List>
        </FAQParagraph>
      </FAQQuestion>

      <FAQSectionHeading>Desktop</FAQSectionHeading>
      <FAQQuestion label="Desktop: When should I bother with Path Substitution?">
        <FAQParagraph>
          You will probably need it, so that your music player can find your
          music files.
        </FAQParagraph>
      </FAQQuestion>
      <FAQQuestion label="Windows: The app doesn't start.">
        <FAQParagraph>
          Try running a free{" "}
          <a href="https://www.ccleaner.com/ccleaner/download">CCleaner</a> Easy
          Clean.
        </FAQParagraph>
      </FAQQuestion>
    </FAQContainer>
  );
};

export default FAQ;
