const firebaseConfig = {
  apiKey: "AIzaSyC4Z1VOjsgZGBLqVU4gWKY48iiQxViKYfg",
  authDomain: "playlistsync-app.firebaseapp.com",
  databaseURL: "https://playlistsync-app.firebaseio.com",
  projectId: "playlistsync-app",
  storageBucket: "playlistsync-app.appspot.com",
  messagingSenderId: "408212985315",
  appId: "1:408212985315:web:1bd10bcbaf24fca645fcd6",
  measurementId: "G-1W569X55J5"
};

export default firebaseConfig;
