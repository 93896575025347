export class P {
  public static readonly users = "users";
  public static readonly configs = "configs";
  public static readonly backups = "backups";
  public static readonly playlists = "playlists";
}

export class Colors {
  public static readonly brand = "#00ABF4";
  public static readonly overBrand = "#ffffff";
  public static readonly darkBrand = "#009ae0";
  public static readonly lightGreyBackground = "#eeeeee";
  public static readonly text = "rgba(0, 0, 0, 0.88)";
  public static readonly freePlan = "rgba(0, 0, 0, 1)";
  public static readonly premiumPlan = "#00ABF4";
  public static readonly android = "#A4C639";
  public static readonly focus = "#E38FF8";
  public static readonly ad = "#FBEBD2";
  public static readonly error = "#F5C0C0";
  public static readonly pillCreate = "#C0F5D5";
  public static readonly pillUpdate = "#CDE6F7";
  public static readonly pillDelete = "#FBEBD2";
  public static readonly pillDanger = "#F5C0C0";
  public static readonly pillUnchanged = "#E8E8E8";
  public static readonly pillCreatePressed = "#1DC861";
  public static readonly pillUpdatePressed = "#4AB4FC";
  public static readonly pillDeletePressed = "#F9B750";
  public static readonly pillDangerPressed = "#E14D4D";
  public static readonly pillUnchangedPressed = "#999696";
}
