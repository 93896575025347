import { Box, Paragraph, ResponsiveContext } from "grommet";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import AppBar from "./components/AppBar";
import LoginContainer from "./components/LoginContainer";
import Spacing from "./components/Spacing";
import SubscribeScreen from "./components/SubscribeScreen";
import Downloads from "./Downloads";
import FAQ from "./FAQ";
import Features from "./Features";
import LoginWrapper from "./LoginWrapper";
import Pricing from "./Pricing";
import PSFooter from "./PSFooter";
import ShowLoginStatus from "./ShowLoginStatus";

const TagLine = styled.p`
  max-width: 980px;
`;

const AppHeader = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  text-align: center;
`;

const App: React.FC = () => {
  const isSmall = React.useContext(ResponsiveContext) === "small";

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/desktopSignInSuccess">
          <LoginContainer>
            <Paragraph>
              Successfully signed in! You can now close this browser tab and
              return to the PlaylistSync application.
            </Paragraph>
          </LoginContainer>
        </Route>
        <Route path="/desktopSignIn/:anonUid">
          <LoginContainer>
            <LoginWrapper desktopSignIn>
              <ShowLoginStatus />
            </LoginWrapper>
          </LoginContainer>
        </Route>
        <Route path="/subscribe">
          <LoginWrapper>
            <AppBar inUserSpace />
            <SubscribeScreen />
          </LoginWrapper>
        </Route>
        <Route path="/faq">
          <AppBar />
          <Box pad="medium">
            <FAQ />
          </Box>
        </Route>
        <Route>
          <AppBar />
          <AppHeader>
            <Box margin="xlarge">
              <Spacing />
              <TagLine>
                PlaylistSync keeps your playlists backed up and syncronized
                across devices. It works with native Android playlists and M3U
                files.
              </TagLine>
              <a id="download" href="#download">
                {" "}
              </a>
              <Spacing />
              <Downloads />
            </Box>
          </AppHeader>
          <Box margin={isSmall ? "medium" : "xlarge"}>
            <a id="features" href="#features">
              {" "}
            </a>
            <Spacing height={5} />
            <Features />
            <a id="pricing" href="#pricing">
              {" "}
            </a>
            <Spacing height={5} />
            <Pricing />
            <a id="faq" href="#faq">
              {" "}
            </a>
            <Spacing height={5} />
            <FAQ />
          </Box>
          <PSFooter />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
