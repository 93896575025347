import { FirebaseReducer, FirestoreReducer } from "react-redux-firebase";
import { TypedUseSelectorHook, useSelector } from "react-redux";

type FirebaseStoreSchema = {
  // userBackups: { [key: string]: PlaylistBackup };
  [queryKey: string]: any;
};

export interface RootStore {
  firebase: FirebaseReducer.Reducer<FirebaseStoreSchema, null>;
  firestore: FirestoreReducer.Reducer;
}

export const useTypedSelector: TypedUseSelectorHook<RootStore> = useSelector;

export type CustomerSummary = {
  active: boolean;
  lastUpdated: Date;
  customerId: string;
  planData?: {
    plan: string;
    productId: string;
    name: string;
    periodStart: Date;
    periodEnd: Date;
    subscriptionId: string;
  };
};
