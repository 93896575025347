import styled from "styled-components";
import { Box } from "grommet";

const LoginContainer = styled(Box)`
  text-align: center;
  margin: 20vh auto;
  max-width: 400px;

  button {
    color: white;
    width: fit-content;
    padding: 8px 20px;
    border-radius: 40px;
  }
`;

export default LoginContainer;
