import React from "react";

import { ReactComponent as Loading } from "../img/loader.svg";
import styled from "styled-components";
import { Box } from "grommet";

const Container = styled(Box)`
  width: 100%;
  height: 80px;
  text-align: center;
`;

const Loader: React.FC = () => {
  return (
    <Container align="center">
      <Loading />
    </Container>
  );
};

export default Loader;
