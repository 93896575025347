import { Box, Heading } from "grommet";
import { Android, Desktop, StatusGood } from "grommet-icons";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { goTo } from "./actions/anchorLinks";
import MainSectionTitle from "./components/MainSectionTitle";
import PrimaryButton from "./components/PrimaryButton";
import Spacing from "./components/Spacing";
import { Colors } from "./consts";

const Item = styled(Box).attrs(props => ({
  direction: "row",
  margin: "xsmall"
}))`
  svg {
    margin-right: 0.5em;
    margin-right: 0.5em;
  }
`;

const PricingBox = styled(Box)`
  width: 333px;
  max-width: 100vw;
`;

const Pricing: React.FC = () => {
  return (
    <Box fill="horizontal">
      <MainSectionTitle>Pricing</MainSectionTitle>
      <Box direction="row" alignContent="center" justify="center" wrap>
        <PricingBox
          direction="column"
          round
          pad="small"
          margin={{ horizontal: "medium", vertical: "large" }}
          elevation="xlarge"
        >
          <Heading level="3" textAlign="center">
            Free
          </Heading>
          <Item>
            <StatusGood color={Colors.freePlan} /> Unlimited number of playlists
          </Item>
          <Item>
            <StatusGood color={Colors.freePlan} /> Unlimited playlist size
          </Item>
          <Item>
            <StatusGood color={Colors.freePlan} />{" "}
            <Android color={Colors.android} /> Backup and restore{" "}
          </Item>
          <Item>
            <StatusGood color={Colors.freePlan} />{" "}
            <Desktop color={Colors.text} /> Import .m3u/.pls/.asx files
          </Item>
          <Item>
            <StatusGood color={Colors.freePlan} />{" "}
            <Desktop color={Colors.text} /> Export 1 demo .m3u file
          </Item>
          <Spacing />
          <PrimaryButton
            color={Colors.freePlan}
            label="Get started for free"
            primary
            fill="horizontal"
            onClick={() => goTo("download")}
          />
        </PricingBox>
        <PricingBox
          direction="column"
          round
          pad="small"
          margin={{ horizontal: "medium", vertical: "large" }}
          elevation="xlarge"
        >
          <Heading level="3" textAlign="center">
            Premium
          </Heading>
          <Item>
            <StatusGood color={Colors.premiumPlan} /> Everything in free
          </Item>
          <Item>
            <StatusGood color={Colors.premiumPlan} /> Version History
          </Item>
          <Item>
            <StatusGood color={Colors.premiumPlan} />{" "}
            <Android color={Colors.android} /> Auto-Backup
          </Item>
          <Item>
            <StatusGood color={Colors.premiumPlan} />{" "}
            <Desktop color={Colors.text} /> Unlimited .m3u restores
          </Item>
          <Item>
            <StatusGood color={Colors.premiumPlan} /> No ads
          </Item>
          <Spacing />
          <Link to="/subscribe">
            <PrimaryButton
              color={Colors.premiumPlan}
              label="Go Premium for 0.50$/month"
              primary
              alignSelf="stretch"
              fill="horizontal"
            />
          </Link>
        </PricingBox>
      </Box>
    </Box>
  );
};

export default Pricing;
