import { Anchor, Box, Heading, Paragraph } from "grommet";
import React from "react";
import styled from "styled-components";
import List from "./components/List";
import MainSectionTitle from "./components/MainSectionTitle";
import Spacing from "./components/Spacing";
import MockupMacbook from "./img/playlistsync-macbook-window.png";
import MockupAndroid from "./img/playlistsync-android.png";

const FeaturesContainer = styled(Box)`
  ul {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
`;

const MockupMacbookImg = styled.img`
  max-width: 100vw;
  max-height: 90vh;
`;

const MockupAndroidImg = styled.img`
  max-width: 400px;
  max-height: 100vh;
`;

const AlignedTitle = styled(Heading)`
  /* Like <ul> */
  padding-left: 25px;
  margin-bottom: 10px;
`;

const Features: React.FC = () => {
  return (
    <FeaturesContainer align="center">
      <MainSectionTitle>Features</MainSectionTitle>
      <Paragraph>1-click backup ★ 1-click restore</Paragraph>
      <Spacing />
      <Box align="center" direction="row" justify="center" wrap>
        <MockupAndroidImg
          src={MockupAndroid}
          alt="PLaylistSync on an Android phone"
        />
        <Box fill={false}>
          <AlignedTitle level="4">Android</AlignedTitle>
          <List>
            <li>
              Backup and restore native Android playlists (compatible with most
              music players and{" "}
              <Anchor href="https://play.google.com/store/apps/details?id=com.desaxed.playlistmanager">
                Playlist Manager
              </Anchor>
              )
            </li>
            <li>Automatic backups</li>
            <li>Version History</li>
            <li>Path substitution</li>
          </List>
        </Box>
      </Box>
      <Spacing height={4} />
      <Box align="center" direction="row" justify="center" wrap>
        <MockupMacbookImg
          src={MockupMacbook}
          alt="PlaylistSync on a macOS computer"
        />
        <div>
          <AlignedTitle level="4">Windows/macOS/Linux</AlignedTitle>
          <List>
            <li>Export .m3u, .m3u8 and .playlistsync files</li>
            <li>Import .m3u, .m3u8, .pls, .asx and .playlistsync files</li>
            <li>
              Path conversion: replace paths and convert slashes (\ and /)
            </li>
          </List>
        </div>
      </Box>
    </FeaturesContainer>
  );
};

export default Features;
