import firebase from "firebase/app";
import "firebase/functions";
import { Box, Button, Heading } from "grommet";
import React, { useState } from "react";
import Stripe from "stripe";
import { Colors } from "../consts";
import ErrorBox from "./ErrorBox";
import FirstParagraph from "./FirstParagraph";
import Loader from "./Loader";

type Props = {
  subscription: Stripe.Subscription;
};

const getCustomerId = (
  customer: string | Stripe.Customer | Stripe.DeletedCustomer | null
): string => {
  if (typeof customer === "string") return customer;
  if (customer == null) return "";
  return customer.id;
};

const SubscriptionStatus: React.FC<Props> = ({ subscription }) => {
  const customerId = getCustomerId(subscription.customer);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const updateSubscription = () => {
    setLoading(true);
    const dev = process.env.NODE_ENV === "development";
    firebase
      .functions()
      .httpsCallable("createcheckoutsessionforsetup")({
        customer_id: customerId,
        subscription_id: subscription.id, 
        live: !dev,
      })
      .then(result => {
        setLoading(false);
        console.log(result);
        if (!result) {
          setError(
            "Failed to start checkout: No information sent back from the server."
          );
          return;
        }
        if (
          result &&
          result.data &&
          result.data.status &&
          result.data.status >= 400
        ) {
          setError(`Error ${result.data.status}: ${result.data.message}`);
          return;
        }

        const sessionId = result.data.sessionId;
        if (!sessionId) {
          setError("Couldn't retrieve a session ID from our server.");
          return;
        }
        const stripe = (window as any).Stripe(
          dev
            ? "pk_test_pgBZOqCTAgOQgo5GAvcj092Y00Qlb5Ap2m"
            : "pk_live_6jiCT4vrpR6FI9m7roLg2Iof00swn67GMH"
        );
        stripe
          .redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: sessionId
          })
          .then((result: any) => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            if (result && result.error && result.error.message)
              setError(result.error.message);
          });
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  const setAutoRenew = (autoRenew: boolean) => {
    setLoading(true);
    const dev = process.env.NODE_ENV === "development";
    firebase
      .functions()
      .httpsCallable("setAutoRenew")({
        auto_renew: autoRenew,
        customer_id: customerId,
        subscription_id: subscription.id, 
        live: !dev
      })
      .then(result => {
        setLoading(false);
        console.log(result);
        if (!result) {
          setError(
            "Failed to set auto renew: No information sent back from the server."
          );
          return;
        }
        if (
          result &&
          result.data &&
          result.data.status &&
          result.data.status >= 400
        ) {
          setError(`Error ${result.data.status}: ${result.data.message}`);
          return;
        }
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <Box
      key={subscription.id}
      round={true}
      background={Colors.lightGreyBackground}
      pad="medium"
      margin={{ vertical: "medium" }}
    >
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <ErrorBox error={error} />
          <Heading level="4">Subscription {subscription.id.substr(4)}</Heading>
          <FirstParagraph>
            Status:{" "}
            {subscription.plan
              ? subscription.plan.active
                ? "Active"
                : "Inactive"
              : "Unkown"}
            <br />
            Start:{" "}
            {new Date(
              subscription.current_period_start * 1000
            ).toLocaleString()}
            <br />
            End:{" "}
            {new Date(subscription.current_period_end * 1000).toLocaleString()}
            <br />
            Auto renew: {subscription.cancel_at_period_end ? "Off" : "On"}
          </FirstParagraph>
          <Button
            label="Update payment method"
            onClick={updateSubscription}
            margin={{ vertical: "xsmall" }}
          />
          {subscription.cancel_at_period_end ? (
            <Button
              margin={{ vertical: "xsmall" }}
              label="Turn on auto-renew"
              onClick={() => setAutoRenew(true)}
            />
          ) : (
            <Button
              margin={{ vertical: "xsmall" }}
              label="Turn off auto-renew"
              onClick={() => setAutoRenew(false)}
            />
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default SubscriptionStatus;
